//
//
// colors.scss
//
//

.bg-body-color {
    background: $body-color;
}

.bg-gradient {
    background: linear-gradient(to right, $primary, $primary-dark) !important;
}

@each $color, $value in $theme-colors {
    .bg-#{$color}-alt {
        background-color: rgba($value, 0.1);
    }
}

.bg-white-alt {
    background-color: rgba($white, 0.1);
}
