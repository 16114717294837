.proposal-section {
    padding: 0 0 12.5rem 0;
}

.proposal-title  {
    text-align: center;
    padding: 2rem 0;
}

@media (min-width: 992px) {
    .proposal-title  {
        padding: 4rem 0;
    }
}

.proposal-form {
    .form-row {
        .col-form-label {
            color: $dark;
        }
    }
    
    .form-group {
        margin-bottom: 1rem;
        &:last-child {
            margin-bottom: 0;
        }
    }
}

@media (min-width: 992px) {
    .proposal-form {
        .form-row {
            .col-form-label {
                font-size: 1.25rem;
            }
        }
        .form-group {
            margin-bottom: 2rem;
        }
    }
}

.proposal-continue-container {
    position: relative;
    min-height: $divider-height;
    text-align: center;
    padding: 2rem 0;
    background: rgb(255, 255, 255);
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 1) 50%,
        rgba(255, 255, 255, 0) 100%
    );
    .divider {
        position: absolute;
        bottom: 0;
    }
    .btn {
        margin: 0 auto 0 auto;
    }
}

.proposal-navigation {
    padding: 0;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 10;
    &::after {
        display: block;
        position: relative;
        content: '';
        height: 100%;
    }
}

.proposal-steps-numbers {
    display: flex;
    align-items: center;
    padding: 2rem 0;
    .step-number {
        background: $light;
        width: 4rem;
        height: 4rem;
        color: $dark;
        font-size: 2em;
        font-weight: 300;
        @include border-radius(50%);
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

$progress-color: lighten($primary, 20%);

.progress-link {
    flex: 1 0;
    text-align: center;
    .progress-link__bar {
        display: block;
        position: relative;
        &:before,
        &:after {
            display: block;
            content: "";
            position: absolute;
            z-index: 5;
            top: 50%;
            width: 50%;
            border-bottom: 2px solid $progress-color;
            margin-top: -1px;
        }
        &:before {
            left: 0;
        }
        &:after {
            left: 50%;
        }
    }
    &:first-child {
        .progress-link__bar {
            &:before {
                display: none;
            }
        }
    }
    &:last-child {
        .progress-link__bar {
            &:after {
                display: none;
            }
        }
    }
    .progress-link__circle {
        position: relative;
        z-index: 10;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3rem;
        height: 3rem;
        @include border-radius(50%);
        background-color: $progress-color;
        margin: 0 auto;
        transition: $transition-base;
    }
    &.active {
        .progress-link__circle {
            background-color: $light;
        }
    }
    &:hover {
        text-decoration: none;
        .progress-link__circle {
            background-color: $secondary;
            color: $white;
        }
    }
}

.proposal-quote {
}

.proposal-quote-information {
    display: flex;
    align-items: center;
    .product-item-icon {
        margin: 0;
    }
}

.proposal-quote-text {
    margin: 0 2rem;
}

.proposal-quote-title {
    margin-bottom: 0.5rem;
}

.proposal-quote-description {
    color: $gray-700;
    margin: 0;
}

.proposal-quote-price {
    flex-shrink: 0;
    margin-left: auto;
    .price-currency {
        margin-left: 0.25rem;
    }
}

.proposal-quote-price-local {
    display: block;
    color: $secondary;
    font-weight: $font-weight-bold;
    .proposal-quote-price-local--big {
        font-size: 2rem;
    }
}

.proposal-quote-price-exchange {
    font-size: 1.125rem;
    color: $gray-600;
}

.proposal-quote-highlight {
    margin-top: 1.5rem;
}

.proposal-quote-highlight-list {
    .proposal-quote-highlight-list-item {
        display: flex;
        margin-bottom: 0.5rem;
    }
}

.proposal-quote-highlight-list-check {
    background-color: lighten($teal, 10%);
    border-radius: 50%;
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    img {
        height: 1.25rem;
        opacity: 0.5;
    }
}

.proposal-sub-title {
    font-size: 1.5rem;
    font-weight: 700;
    display: block;
    margin: 2rem 0;
    color: $primary;
}

.coverage-title {
    padding: 2rem;
}

.proposal-coverage-list-item {
    display: flex;
    &:nth-child(even) {
        background: #f7f9fc;
    }
    &:not(:first-child) {
        border-top: 1px solid #ced4da;
    }
    .coverage-title {
        padding: 1.5rem 2rem;
        color: $gray-900
    }
    .coverage-amount {
        padding: 1.5rem 2rem;
        text-align: right;
    }
}

.proposal-summary {
    width: 100%;
}

.proposal-summary-item {
    margin-bottom: 1rem;
}

.last {
    .proposal-summary-item {
        margin-bottom: 0;
    }
}

.proposal-summary-title {
    color: $primary;
    font-weight: 600;
}

.proposal-payment {
    margin-top: 4rem;
}

.proposal-payment-card {
    .jp-card-container {
        width: 100%;
        margin-left: auto;
    }
}

.proposal-payment-information {
    margin-top: 1rem;
}

.proposal-success {
    display: flex;
    flex-direction: column;
    align-items: center;
    .proposal-success-buttons {
        display: flex;
    }
}

.modal-full-height {
    height: calc(100% - 4rem);
    .modal-content {
        height: 100%;
    }
    .modal-body {
        display: flex;
    }
}

.object-proposal-preview {
    flex: 1;
    width: 100%;
    border: none;
}

.loader-proposal-preview {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    span {
        font-size: 2rem;
        color: $headings-color;
    }
}

.installment-selector {
    cursor: pointer;
    &:hover {
        background-color: $secondary-hover;
    }
}