.select2-container--valde {
  .select2-search--dropdown {
    padding: $input-btn-padding-x $input-btn-padding-y;
  }
  .select2-search--dropdown .select2-search__field {
    padding: $input-btn-padding-x $input-btn-padding-y;
    width: 100%;
  }
  .select2-results__option {
      padding: $input-btn-padding-x $input-btn-padding-y;
  }
}