// Fonts
@import url("https://fonts.googleapis.com/css?family=Nunito");

// Variables
@import "~bootstrap/scss/functions";
@import "variables";
@import "plugin-variables";

// Bootstrap
@import "~bootstrap/scss/bootstrap";

// Components
@import "mixins";
@import "custom/components";

// Plugins
@import "~sweetalert2/src/sweetalert2";
@import "~select2/src/scss/core.scss";
@import "valde/select2/layout.scss";

// Custom
@import "user";
@import "proposal";
@import "form";
