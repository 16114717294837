//
//
// footers.scss
//
//

.footer-top {
    padding: 3rem 0 0 0;
    hr {
        margin: 3rem 0 0 0;
    }
}

.footer-bottom {
    padding: 1rem 0;
}

.footer-content-list {
    margin-bottom: 0;
}

.footer-content-list-item {
    &:last-child {
        margin-bottom: 0 !important;
    }
}

@include media-breakpoint-up(md) {
    .footer-top {
        padding: 4rem 0 0 0;
        hr {
            margin: 4rem 0 0 0;
        }
    }
}

@include media-breakpoint-up(lg) {
    .footer-top {
        padding: 6rem 0 0 0;
        hr {
            margin: 6rem 0 0 0;
        }
    }
}
