@import "single";
@import "multiple";
@import "dropdown";

// basic
.select2-container {
  display: block;

  *:focus {
    outline: 0;
  }
}

// input-group
.input-group .select2-container--valde {
  flex-grow: 1;
}
// for prepended input
.input-group-prepend ~ .select2-container--valde .select2-selection {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
// for appended input
.input-group > .select2-container--valde:not(:last-child) .select2-selection {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--valde {

  // input box
  .select2-selection {
    background-color: $input-bg;
    border: $input-border-width solid $input-border-color;
    @include border-radius($input-border-radius);
    @include box-shadow($input-box-shadow);
    @include transition($input-transition);
    width: 100%;
  }

  // focused input box
  &.select2-container--focus {
    .select2-selection {
      border-color: $input-focus-border-color;
      box-shadow: $input-focus-box-shadow;
    }

    // when open, hide bottom border
    &.select2-container--open .select2-selection {
      border-bottom: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  // disabled input box
  &.select2-container--disabled,
  &.select2-container--disabled.select2-container--focus {
    .select2-selection {
      background-color: $input-disabled-bg;
      cursor: not-allowed;
      border-color: $input-border-color;
      box-shadow: none;
    }
    .select2-search__field {
      background-color: transparent;
    }
  }

  // validated input box
  select.is-invalid ~ & .select2-selection,
  form.was-validated select:invalid ~ & .select2-selection {
    border-color: $danger;
  }
  select.is-valid ~ & .select2-selection,
  form.was-validated select:valid ~ & .select2-selection {
    border-color: $success;
  }

  // dropdown
  .select2-dropdown {
    border-color: $input-border-color;
    border-radius: 0; // overwrite select2.css

    // dropdown opened below
    &.select2-dropdown--below {
      border-top: none;
      @include border-bottom-radius($input-border-radius);
    }

    // dropdown opened above
    &.select2-dropdown--above {
      border-top: 1px solid $input-border-color;
      @include border-top-radius($input-border-radius);
    }

    // selected item
    .select2-results__option[aria-selected=true] {
      background-color: darken($light, 5%);
    }
  }

  // mouse hovered item
  .select2-results__option--highlighted,
  .select2-results__option--highlighted.select2-results__option[aria-selected=true] {
    background-color: $primary;
    color: $light;
  }

  // for <optgroup>
  .select2-results__option[role=group] {
    padding: 0;

    .select2-results__options--nested .select2-results__option {
      padding-left: 1em;
    }
  }

  // fixes vertical overflow
  .select2-results > .select2-results__options {
    max-height: 15em;
    overflow-y: auto;
  }

  .select2-results__group {
    padding: 6px;
    display: list-item;
    color: $secondary;
  }

  // all clear button
  .select2-selection__clear {
    $bg: #c8c8c8;

    width: 0.9em;
    height: 0.9em;
    line-height: 0.75em;
    padding-left: $input-btn-padding-x;
    margin-top: 0.7em;
    border-radius: 100%;

    background-color: $bg;
    color: $light;
    float: right;
    margin-right: 0.3em;

    &:hover {
      background-color: darken($bg, 10%);
    }
  }
}
