//
//
// cards.scss
//
//

.card {
  &.bg-white {
    border-color: rgba(0, 0, 0, 0);
    background-color: rgba($white, 0.05) !important;
    .card-footer {
      border-color: rgba($white, 0.1);
    }
  }
}

.card-footer {
  background-color: transparent;
}

@include media-breakpoint-down(sm) {
  .card-body {
    padding: $spacer * 1.5;
  }
}

a.card {
  color: $body-color;
  font-weight: $font-weight-normal;
}
