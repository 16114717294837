//
//
// twitterfetcher.scss
//
//

.user {
  img {
    border-radius: 50%;
    max-height: $spacer * 2;
  }
  [data-scribe='component:author'] {
    display: none;
  }
}

.timePosted {
  > a {
    color: $gray-500;
  }
}

.tweet {
  word-break: break-word;
}
