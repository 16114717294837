// İzinyolu

html,
body {
    height: 100%;
}
body {
    display: flex;
    flex-direction: column;
}
.content {
    flex: 1 0 auto;
}
.footer {
    flex-shrink: 0;
}

.hidden {
    display: none;
}

.cookie-bar-text {
    font-size: 0.875rem;
}

.cookie-bar-btn {
    flex-shrink: 0;
}

.home-section {
    padding-top: 2rem;
    padding-bottom: 0;
}

@media (min-width: 992px) {
    .home-section {
        padding-top: 4rem;
    }
}

.product-selector  {
    display: flex;
}

.product-item-container {
    width: 100%;
    margin-bottom: 1rem;
    &:last-child {
        margin-bottom: 0;
    }
}

.product-item {
    width: 100%;
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    background: $white;
    @include border-radius($border-radius);
}

.product-item {
    color: $body-color;
    &:hover {
        color: $body-color;
    }
}

.product-item-action {
    display: block;
    margin-top: 1rem;
}

.product-item-text {
    display: block;
    margin-bottom: 1rem;
}

.product-item-icon {
    display: block;
    width: 48px;
    margin-right: 1rem;
}

@media (min-width: 576px) {
    .product-item-icon {
        width: 64px;
        margin-right: 2rem;
    }
}

.product-detail-card {
    padding: 2rem 1rem;
}

.product-item-title {
    margin: 0;
}

.product-item-details-top {
    display: flex;
    align-items: center;
}

.product-detail-icon {
    height: 72px;
    margin-bottom: 2rem;
}
.logo-caption-sm {
    height: 1.5rem;
}
.logo-caption-md {
    height: 2rem;
}
.light-badge {
    background-color: $gray-300;
    color: $gray-600;
}


// Forms

.input-group-lg {
    .input-group-svg {
        width: 68px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

$custom-control-indicator-size-lg: 1.75rem;

.custom-control-lg {
    @include font-size($input-font-size-lg);
    display: flex;
    align-items: center;
    .custom-control-label {
        margin-left: $spacer;
        &:before {
            position: absolute;
            top: (
                    $input-font-size-lg * $line-height-base -
                        $custom-control-indicator-size-lg
                ) / 2;
            left: -($custom-control-gutter + $custom-control-indicator-size-lg);
            display: block;
            width: $custom-control-indicator-size-lg;
            height: $custom-control-indicator-size-lg;
            pointer-events: none;
            content: "";
            background-color: $custom-control-indicator-bg;
            border: $custom-control-indicator-border-color solid
                $custom-control-indicator-border-width;
            @include box-shadow($custom-control-indicator-box-shadow);
        }
        &::after {
            position: absolute;
            top: (
                    $input-font-size-lg * $line-height-base -
                        $custom-control-indicator-size-lg
                ) / 2;
            left: -($custom-control-gutter + $custom-control-indicator-size-lg);
            display: block;
            width: $custom-control-indicator-size-lg;
            height: $custom-control-indicator-size-lg;
            content: "";
            background: no-repeat 50% / #{$custom-control-indicator-bg-size};
        }
    }
}

.header {
    &.position-fixed {
        @include box-shadow($box-shadow-sm);
    }
}

.alert-error-list {
    text-align: left;
}