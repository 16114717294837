//
//
// page-transitions.scss
//
//
// An optional component for soft fading page transitions

body {
  transition: opacity 0.4s ease;
  &.fade-page {
    opacity: 0;
  }
}
