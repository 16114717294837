.select2-container--valde {
  .select2-selection--single {

    // height of input box
    height: $input-height !important;

    // placeholder
    .select2-selection__placeholder {
      // #757575 is same color of placeholder (on Chrome)
      color: #757575;
      line-height: $input-height-inner;
    }

    // arrow
    .select2-selection__arrow {
      position: absolute;
      top: 50%;
      right: $input-btn-border-width;
      width: $input-btn-padding-x*2;

      b {
        top: 60%;
        border-color: $dark transparent transparent transparent;
        border-style: solid;
        border-width: 5px 4px 0 4px;
        width: 0;
        height: 0;
        left: 50%;
        margin-left: -4px;
        margin-top: -2px;
        position: absolute;
      }
    }

    // selected item
    .select2-selection__rendered {
      line-height: $input-height-inner;
      display: block;
      padding-left: $input-btn-padding-x;
      padding-right: $input-btn-padding-x*2;
  
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      font-size: $input-font-size;

    }

  }
}

// search box
.select2-search--dropdown .select2-search__field {
  border: $input-border-width solid $input-border-color;
  @include border-radius($input-border-radius);
}

// no result message
.select2-results__message {
  color: $secondary;
}

.form-control-lg + .select2-container--valde {
  .select2-selection--single {

    // height of input box
    height: $input-height-lg !important;

    // placeholder
    .select2-selection__placeholder {
      // #757575 is same color of placeholder (on Chrome)
      color: #757575;
      line-height: calc(
          #{$input-line-height-lg * 1em} + #{$input-btn-padding-y-lg * 2}
      );
    }

    // selected item
    .select2-selection__rendered {
      line-height: calc(
        #{$input-line-height-lg * 1rem} + #{$input-btn-padding-y-lg * 2}
      );
      padding-left: $input-btn-padding-x-lg;
      padding-right: $input-btn-padding-x-lg*2;
      font-size: $input-font-size-lg;
    }

  }
}