//
//
// avatars.scss
//
//

.avatar {
  border-radius: 50%;
  height: $spacer * 3;
  width: $spacer * 3;
  flex-shrink: 0;
  &.avatar-xs,
  &.avatar-sm {
    width: $spacer * 2.25;
    height: $spacer * 2.25;
  }
  &.avatar-lg {
    width: $spacer * 4.5;
    height: $spacer * 4.5;
  }
  &.avatar-xl {
    width: $spacer * 6;
    height: $spacer * 6;
  }
}

.avatar-group {
  display: flex;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
  > li {
    position: relative;
    &:not(:last-child) {
      margin-right: -$spacer;
    }
  }
  .avatar {
    border: 2px solid $white;
  }
}

@include media-breakpoint-up(md) {
  .avatar {
    height: $spacer * 4.5;
    width: $spacer * 4.5;
    &.avatar-sm {
      width: $spacer * 3;
      height: $spacer * 3;
    }
    &.avatar-lg {
      width: $spacer * 6;
      height: $spacer * 6;
    }
  }
}

@include media-breakpoint-up(lg) {
  .avatar {
    &.avatar-xl {
      width: $spacer * 10.5;
      height: $spacer * 10.5;
    }
  }
  .avatar-group {
    &.avatar-group-lg {
      > li {
        &:not(:last-child) {
          margin-right: -$spacer * 2;
        }
      }
    }
  }
}

.avatar-container {
  position: relative;
  display: inline-block;
  > .badge {
    position: absolute;
    border: 2px solid $white;
    top: 0;
    right: 0;
    &:empty {
      border-radius: 50%;
      width: 25%;
      height: 25%;
      display: block;
      max-height: 20px;
      max-width: 20px;
      min-height: 14px;
      min-width: 14px;
      padding: 0;
    }
  }
}
